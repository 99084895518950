<template>
  <div class="title">巡检信息</div>
  <div class="center-outer">
    <div class="center-inner">
      <div class="bubbles" id="bubbles">
        <h1>暂无数据</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods:{
    randomValue(arr) {
      return arr[Math.floor(Math.random() * arr.length)];
    }
  },
  mounted() {
    let dom = document.getElementById('bubbles');
    let _width = dom.getBoundingClientRect().width;
    var sArray = [4, 6, 8, 10];
    var bArray = [];
    for (var i = 0; i < _width; i++) {
      bArray.push(i);
    }
    setInterval(()=>{
      var size = this.randomValue(sArray);
      var child = document.createElement('div');
      child.setAttribute('style', `left:${this.randomValue(bArray)}px;width:${size}px;height:${size}px;border-radius: 100%;position: absolute;bottom: 10px;background-color: #fff`);
      dom.appendChild(child);
      child.animate([
        { transform: 'translateY(0px)',opacity:0 },
        { transform: 'translateY(-45px)',opacity:.7 }
      ], {
        duration: 3000,
      })
      setTimeout(()=>{
        child.remove();
      },3000)
    }, 350);
  }
}
</script>

<style scoped lang="scss">
.title{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 890px;
  height: 67px;
  background: url("./image/title.png") no-repeat center;
  background-size: 100% 100%;
  font-size: 38px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 67px;
}
.center-outer {
  display: table;
  width: 100%;
  height: 100%;
  .center-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    .bubbles {
      display: inline-block;
      font-family: arial;
      position: relative;
      h1 {
        width: 200px;
        text-align: center;
        position: relative;
        margin: 1em 0 0;
        font-family: 'Luckiest Guy', cursive;
        color: #fff;
        z-index: 2;
      }
    }
  }
}


</style>