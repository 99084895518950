<template>
  <div v-if="formLabelAlign.length > 0">
    <div class="title">巡检信息</div>

    <main class="main">
      <vue-seamless-scroll v-if="isScroll" :class-option="optionSingleHeightTime" :data="formLabelAlign" class="gjDiv">
        <div class="bodyDiv" v-for="(item, index) in formLabelAlign" :key="index">
          <div class="infoDiv">
            <div class="l">
              <el-form label-position="left" label-width="120px" :model="formLabelAlign">
                <el-row>
                  <el-col :span="6">
                    <div class="single">巡检区域</div>
                  </el-col>
                  <el-col :span="12">
                    <div class="single">巡检内容</div>
                  </el-col>
                  <el-col :span="6">
                    <div class="single">巡检距离</div>
                  </el-col>


                  <el-col :span="6">
                    <div class="singleone">{{ item.deviceRegionName }}</div>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="设备资源码：">
                      <div>{{ item.code }}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="巡查备注：">
                      <div>{{ item.remarkPatrol || '暂无' }}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="巡检距离：">
                      <div>{{ getDistance(item.distance) }}</div>
                    </el-form-item>
                  </el-col>

                  <el-col :span="6">
                    <div class="single">巡检人员</div>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="设备资源码：">
                      <div>{{ item.mac }}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="巡查备注：">
                      <div>{{ item.processResultsName }}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <div class="single">巡检时间</div>
                  </el-col>

                  <el-col :span="6">
                    <el-form-item label="巡检人员：">
                      <div>{{ item.userPhone }}（{{ item.userName }}）</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="查询结果：">
                      <div>{{ item.patrolResultsName }}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="处理备注：">
                      <div>{{ item.remarkProcess || '暂无' }}</div>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="巡检时间：">
                      <div>{{ item.addtime }}</div>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <div class="r">
              <div class="single">现场图片</div>
              <div class="imgDiv" v-if="item.imgUrl">
                <el-image
                    :append-to-body="true"
                    :preview-src-list="item.imgUrl.split('&&')"
                    v-for="(url, index) in item.imgUrl.split('&&')" :key="index"
                    style="width: 100px; height: 75px"
                    :src="url"
                    fit="cover"
                />
              </div>
            </div>
          </div>
        </div>
      </vue-seamless-scroll>

    </main>
  </div>

  <Empty v-else/>
</template>

<script>
import { getPatrolRecordList } from "../../api/jy";
import Empty from './empty'
export default {
  data(){
    return{
      isScroll:true,
      formLabelAlign:{}
    }
  },
  components:{
    Empty
  },
  computed:{
    optionSingleHeightTime(){
      return{
        openWatch: true,
        limitMoveNum: 3,
        singleHeight: 0,
        singleWidth: 0,
        waitTime: 1000
      }
    },

    getDistance(){
      return function (value){
        if(value < 100){
          return '小于100m'
        }else if(value < 1000){
          return value + 'm'
        }else if(value < 10000){
          return `${(value / 1000).toFixed(1)}km`
        }else {
          return '>10km'
        }
      }
    },
  },
  mounted() {
    setInterval(()=>{
      getPatrolRecordList().then(res=>{
        this.isScroll = false
        setTimeout(()=>{
          this.isScroll = true
        },16)
        this.formLabelAlign = res.data.data;
      })
    },1000*60*2)
    getPatrolRecordList().then(res=>{
      this.formLabelAlign = res.data.data;
    })
  }
}
</script>

<style lang="scss" scoped>
.gjDiv{
  width: 100%;
  height: 950px;
  overflow: hidden;
}

::v-deep .el-form-item{
  align-items: center;
  margin: 0;
  .el-form-item__label{
    font-size: 16px !important;
  }
  .el-form-item__content{
    font-size: 16px !important;
    text-align: left;
  }
}

.single{
  font-size: 24px;
  font-weight: 400;
  color: #00FFD5;
  text-align: left;
}

.title{
  margin: 0 auto;
  width: 890px;
  height: 67px;
  background: url("./image/title.png") no-repeat center;
  background-size: 100% 100%;
  font-size: 38px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 67px;
}

.main{
  margin: 30px auto 0 auto;
  width: 98%;
  .bodyDiv{
    border-top: 10px solid #0e3d34;
    border-bottom: 10px solid #0e3d34;
    .infoDiv{
      padding: 30px;
      display: flex;
      justify-content: space-between;
      .l{
        width: calc(100% - 420px);
        .singleone{
          font-size: 16px;
          font-weight: 300;
          color: #50C9B1;
          text-align: left;
          padding-top: 11px;
        }
      }
      .r{
        width: 360px;
        .imgDiv{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 37px;
          width: 100%;
          height: 75px;
        }
      }
    }
  }
}
</style>